@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .row {
        display: flex;
        flex-wrap: wrap;
    }
}

@font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: url('../public/fonts/Poppins-Light.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('../public/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url('../public/fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url('../public/fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('../public/fonts/Poppins-Bold.ttf');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 800;
    src: url('../public/fonts/Poppins-Black.ttf');
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #ccc;
}

::-webkit-scrollbar-thumb {
    background: #FA9B27;
}

::-webkit-scrollbar-thumb:hover {
    background: #000072;
}


.loader {
    width: 110px;
    height: 103px;
    position: relative;
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    inset: 0;
    background: url('../public/images/fevicon.png');
    background-repeat: no-repeat;
}

.loader:after {
    animation: pulse 1s infinite;
    transform: perspective(336px) translateZ(0px);
}

@keyframes pulse {
    to {
        transform: perspective(336px) translateZ(168px);
        opacity: 0;
    }
}


@media (max-width: 991px) {
    #navbar-right {
        transform: scaleY(0);
        transform-origin: top;
    }

    #navbar-right.open {
        transform: scaleY(1);
    }
}